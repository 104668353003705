@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'
























































































































































































































































































































































































































































































































































































































































































.process-color
  color $process
.bloc-item
  position relative
.toggler-collapse
  border 1px solid $process
  border-radius 100%
  width 20px
  height 20px
  position absolute
  right 0;
  top 7px;
  cursor pointer
.arrow
  border-top 1px solid $process
  border-left 1px solid $process
  transform rotate(45deg)
  width 6px
  height 6px
  margin-left 6px
  margin-top 7px
.display-none
  display none
.rotate
  transform rotate(180deg)
